@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'DM Sans';
}

body {
    margin: 0;
    background-color: #F8F8FF;
}
